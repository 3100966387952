import { debounceFn } from '@/utils/common'

// 定义一个名为 debounce 的指令
const debounce = {
  // 绑定的值为 el，和 binding
  // binding 的值为指令绑定的值，binding 中有哪些值，可以去 vue 官网中查看自定义指令
  bind (el, binding) {
    let execFunc
    // 在函数传参与不传参调用的时候，打印出来的 binding.value 是不同的
    // v-debounce="getCount" 时，binding.value 是函数
    // v-debounce="getCount(index)" 时，binding.value 是 getCount(index) 的返回值，为 undefinded
    // 所以传参时调用需要包一层，如 v-debounce="[() => {getCount(index)}]"，这样取 [binding.value][0] 即为所需函数
    if (binding.value instanceof Array) {
      // 函数传参
      const [func, time = 1000] = binding.value
      execFunc = debounceFn(func, time)
    } else {
      // 函数不传参
      execFunc = debounceFn(binding.value, 500)
    }
    el.addEventListener('click', execFunc)
  }
}

export default debounce
