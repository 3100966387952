import axios from 'axios'
import { VueAxios } from './axios'
import Vue from 'vue'
import store from '@/store'
// import router from '@/router'
import config from '@/config'
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: config.baseURL,
  timeout: 60000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    console.log(error)
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = store.getters.token
  const chatToken = store.getters.chatToken
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  config.headers.platform = 'MP-WEIXIN'
  if (token) {
    config.headers['access-token'] = token
  }
  if (chatToken) {
    config.headers.authorization = config.headers.authorization || `Bearer ${chatToken}`
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  if (response.config.baseURL === config.chatBaseUrl) { // 星爷接口
    if (response.status === 200) {
      return response.data
    }
  } else {
    if (response.status === 200) {
      if (response.data.status === 200 || response.data.code === 200) {
        return response.data
      } else {
        // if (response.data.status === 401) {
        //   store.dispatch('LogOut', {}).then(() => {
        //     router.push({ path: '/login' })
        //   })
        // }
        Vue.prototype.$message.error(response.data.message)
        return Promise.reject(response.data)
      }
    }
  }
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
