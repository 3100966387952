<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
@import "./assets/css/reset.less";

html,
body {
  height: 100%;
  margin: 0;
  background-color: #f5f5f5;
  color: #030303;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.line-clamp-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.noData {
  padding-top: 75px;
  color: #5E6272;
  text-align: center;
  .emptyimg {
    width: 175px;
  }
  .empty-text {
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    margin-top: 2px;
  }
}

</style>
