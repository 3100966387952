/**
* @desc 函数防抖
* @param func 函数
* @param wait 延迟执行毫秒数
* @param immediate true表示立即执行，false表示非立即执行
*/

export function debounceFn (func, wait, immediate = true) {
  // 定义一个timeout计时器
  let timeout
  return function () {
    // 如果 timer 有值，代表有执行过定时器，就将定时器清除
    if (timeout) clearTimeout(timeout)
    // 默认立即执行方法，延后执行的话，会让人感觉有卡顿
    if (immediate) {
      // 定义现在是否能执行
      const now = !timeout
      if (now) func.apply(this, arguments)
      // 不论timeout有没有值，都重新给timeout新添加一个定时器
      // 等待wait时间后，将timeout设为null，代表可以继续执行次function
      timeout = setTimeout(() => {
        timeout = null
      }, wait)
    } else {
      // 如果不是立即执行此函数，则在等待wait时间后执行方法
      timeout = setTimeout(() => {
        func.apply(this, arguments)
      }, wait)
    }
  }
}

export function getQueryVariable (variable) {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] === variable) { return pair[1] }
  }
  return (false)
}

export function getBase64Image (src) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest()
    xhr.open('get', src, true)
    xhr.responseType = 'blob'
    xhr.onload = function () {
      if (this.status === 200) {
        const blob = this.response
        const oFileReader = new FileReader()
        oFileReader.onloadend = function (e) {
          const base64 = e.target.result
          resolve(base64)
        }
        oFileReader.readAsDataURL(blob)
      }
    }
    xhr.send()
  })
}

export function base64ToFile (base64Str) {
  console.log(111)
  const arr = base64Str.split(',')
  console.log(arr[0])
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  console.log(222)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  console.log(333)
  return new File([u8arr], `${new Date().getTime()}.png`, { type: mime })
}
