import Vue from 'vue'
import Vuex from 'vuex'
import { user, setting } from './modules'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters,
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    setting
  }
})
