import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Carousel, CarouselItem, Message, Popover, MessageBox, Dialog, Input, Slider, ColorPicker, Loading, Progress } from 'element-ui'
import './assets/theme/index.css'
import storage from '@/utils/storage'// 引入

import VueClipBoard from 'vue-clipboard2'

import Directive from './directives' // 自定义指令

import '@/assets/font/font.css'

// import vConsole from '@/vconsole.js'
// Vue.prototype.vConsole = vConsole

Vue.use(Directive)

Vue.use(VueClipBoard)

Vue.prototype.$storage = storage// 加入vue的原型对象

Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Popover)
Vue.use(Dialog)
Vue.use(Input)
Vue.use(Slider)
Vue.use(ColorPicker)
Vue.use(Loading.directive)
Vue.use(Progress)

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$loading = Loading.service

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
