export default {
  state: {
    hideLeftNav: false
  },
  mutations: {
    SET_HIDE: (state, value) => {
      state.hideLeftNav = value
    }
  },
  actions: {
    ChangeHide ({ commit }, data) {
      commit('SET_HIDE', data)
    }
  },
  getters: {

  }
}
