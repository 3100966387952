import checkLogin from './checkLogin'
import debounce from './debounce'

const directives = {
  checkLogin,
  debounce
}

export default {
  install (Vue) {
    Object.keys(directives).forEach(key => {
      Vue.directive(key, directives[key])
    })
  }
}
