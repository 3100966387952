// 点击进行登录权限判断
import store from '@/store'
import router from '@/router'

const checkLogin = {
  inserted: (el, binding, vnode) => {
    el._handler = e => {
      // 判断是否登录
      if (store.getters.token) {
        // 如果绑定了函数，则调用函数
        const { expression, arg } = binding
        if (expression && vnode.context[expression]) {
          vnode.context[expression](arg)
        } else {
          binding.value(arg)
        }
      } else {
        router.push({ path: '/login' })
      }
    }

    // 添加事件监听
    setTimeout(_ => {
      el.addEventListener('click', el._handler)
    }, 0)
  },
  unbind (el) {
    // 解除事件监听
    el.removeEventListener('click', el._handler)
  }
}

export default checkLogin
